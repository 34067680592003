// export interface FileIndex {
//   [key: string]: any;
// }

export const images = {
  'milestones': require('./images/home-milestones.jpg'),
  'infant-massage': require('./images/baby-massage.jpg'),
  'services': require('./images/services.jpg'),
  'method': require('./images/the-method.jpg'),
  'about-me': require('./images/about-me.jpg'),
  'pre-birth': require('./images/pre-birth.jpg'),
  'pre-birth-bw': require('./images/pre-birth-bw.jpg'),
  'welcome': require('./images/welcome.jpg'),
  'welcome-bw': require('./images/welcome-bw.jpg'),
  'rolling': require('./images/rolling.jpg'),
  'rolling-bw': require('./images/rolling-bw.jpg'),
  'army-crawl': require('./images/army-crawl.jpg'),
  'army-crawl-bw': require('./images/army-crawl-bw.jpg'),
  'crawling': require('./images/crawling.jpg'),
  'crawling-bw': require('./images/crawling-bw.jpg'),
  'sitting-independently': require('./images/sitting-independently.jpg'),
  'sitting-independently-bw': require('./images/sitting-independently-bw.jpg'),
  'standing': require('./images/standing.jpg'),
  'standing-bw': require('./images/standing-bw.jpg'),
  'walking-independently': require('./images/walking-independently.jpg'),
  'walking-independently-bw': require('./images/walking-independently-bw.jpg'),
  'services-header': require('./images/services-header.jpg'),
  'milestones-header': require('./images/milestones-header.jpg'),
  'challenge': require('./images/challenge.jpg'),
  'holistic': require('./images/holistic.jpg'),
  'feet': require('./images/feet.png'),
  'testimonials-1': require('./images/testimonials-1.jpg'),
  'testimonials-4': require('./images/testimonials-4.jpg'),
  'lilach-home-1': require('./images/lilach-home-1.jpg'),
  'foot-yellow': require('./images/foot-yellow.png'),
  'foot-red': require('./images/foot-red.png'),
  'foot-green': require('./images/foot-green.png'),
  'foot-blue': require('./images/foot-blue.png'),

  'gallery_01': require('./images/gallery_01.jpg'),
  'gallery_01_t': require('./images/gallery_01_t.jpg'),
  'gallery_02': require('./images/gallery_02.jpg'),
  'gallery_02_t': require('./images/gallery_02_t.jpg'),
  'gallery_03': require('./images/gallery_03.jpg'),
  'gallery_03_t': require('./images/gallery_03_t.jpg'),
  'gallery_04': require('./images/gallery_04.jpg'),
  'gallery_04_t': require('./images/gallery_04_t.jpg'),
  'gallery_05': require('./images/gallery_05.jpg'),
  'gallery_05_t': require('./images/gallery_05_t.jpg'),
  'gallery_06': require('./images/gallery_06.jpg'),
  'gallery_06_t': require('./images/gallery_06_t.jpg'),
  'gallery_07': require('./images/gallery_07.jpg'),
  'gallery_07_t': require('./images/gallery_07_t.jpg'),
  'gallery_08': require('./images/gallery_08.jpg'),
  'gallery_08_t': require('./images/gallery_08_t.jpg'),
}

import * as React from 'react';
import { PageLink } from '../controls/page-link';
import { images } from '../images';
import './../main.scss';
import { Title } from '../controls/title';
import { CallToAction } from '../call-to-action';
var milestones = require('./milestones.json');
var classNames = require('classnames');

export class Milestones extends React.Component {
  render() {
    window.scrollTo(0, 0)
    var pageLinks = milestones.map((milestone, index) => (
      <div key={index}>
        {index === 0 ? <div className={classNames("page-link-top-margin")}></div> : null}
        <PageLink
          href={`/milestone/${milestone.href}`}
          image={images[milestone.image_small]}
          imageOver={images[milestone.image]}
          title={milestone.title}
          subtitle={milestone.subtitle}
        />
        {index < milestones.length - 1 ? <div className={classNames("page-link-separator")}></div> : null}
      </div>
    ));

    return (
      <>
        <Title text="Milestones" />
        <div className={classNames("page-header-image")}><img src={images['milestones-header']} alt='' /></div>
        <div className={classNames("content", "column")}>{pageLinks}</div>
        <CallToAction/>
      </>
    );
  }
}

// export interface FileIndex {
//   [key: string]: any;
// }

export const texts = {
  'about': require('./texts/about.json'),
  'be-prepared': require('./texts/be-prepared.json'),
  'welcome-new-world': require('./texts/welcome-new-world.json'),
  'rolling': require('./texts/rolling.json'),
  'army-crawl': require('./texts/army-crawl.json'),
  'crawling': require('./texts/crawling.json'),
  'sitting-independently': require('./texts/sitting-independently.json'),
  'standing': require('./texts/standing.json'),
  'walking-independently': require('./texts/walking-independently.json'),
  'teach-support-challenge': require('./texts/teach-support-challenge.json'),
  'hands-on': require('./texts/hands-on.json'),
  'infant-massage': require('./texts/infant-massage.json'),
  'testimonials': require('./texts/testimonials.json'),
  'disclaimer': require('./texts/disclaimer.json'),
  'terms-of-use': require('./texts/terms-of-use.json')
}

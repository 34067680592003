import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'
import { App } from './app';
import * as serviceWorker from './serviceWorker';

if (typeof document !== 'undefined') {
  ReactDOM.render(
    <HashRouter basename={process.env.PUBLIC_URL}>
      <App />
    </HashRouter>,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
